<template>
	<el-form class="page" :model="starosneGrupe" :rules="rules" ref="form" :label-position="'top'">
		<el-row>
			<el-col>
				<el-form-item size="large">
					<el-tooltip class="item" effect="dark" :content="$t('global.nazad')" placement="top">
						<el-button @click="$utils('goBack')" type="info" icon="fas fa-hand-point-left" circle></el-button>
					</el-tooltip>
				</el-form-item>
			</el-col>
		</el-row>
		<el-row>
			<el-col :xs="24" :sm="8" :md="6" :lg="6" :xl="6">
				<el-form-item :label="$t('starosneGrupe.naziv')" prop="naziv">
					<el-input v-model="starosneGrupe.naziv" :maxLength="100"/>
				</el-form-item>
			</el-col>
		</el-row>
		<el-row>
			<el-col>
				<el-form-item size="large">
					<el-button :disabled="onSubmitLoading" v-loading="onSubmitLoading" type="success" @click="onSubmit">{{ $t('global.sacuvaj') }}</el-button>
					<el-button type="info" @click="$utils('goBack')">{{ $t('global.odustani') }}</el-button>
				</el-form-item>
			</el-col>
		</el-row>
	</el-form>
</template>

<script>
export default {
	name: 'starosne-grupe-edit',
	data() {
		return {
			fullscreenLoading: true,
			onSubmitLoading: false,
			id: '',
			formCreate: '',
			starosneGrupe: {
				naziv: ''
			},
			rules: {
				naziv: [{ required: true, max: 100, trigger: 'blur', message: this.$t('message.obavezno') }]
			},
			continentList: ['eu']
		};
	},
	mounted() {
		this.id = this.$route.params.id;
		this.formCreate = this.id === 'create';

		this.$store.dispatch('setHeader', {
			title: this.$t('starosneGrupe.starosneGrupe')
		});

		this.$utils('stopLoadingAfter', [this.getStarosneGrupe()]);
	},
	methods: {
		getStarosneGrupe() {
			if (!this.formCreate) {
				return this.$get('starosneGrupe', { id: this.id }).then(data => {
					this.starosneGrupe = data;
				});
			}
		},
		onSubmit() {
			this.onSubmitLoading = true;
			return this.$refs.form.validate().then(() => {
				let data = JSON.stringify(this.starosneGrupe);

				if (this.formCreate)
					this.$save('starosneGrupe', data);
				else
					this.$update('starosneGrupe', { id: this.id }, data);
			}).catch((err) => {
				this.onSubmitLoading = false;
				return err;
			});
		}
	}
};
</script>
